<!-- 详情与编辑、添加都放同一页 -->
<template>
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p>活动信息</p>
      <el-form class="mt-24" label-width="120px" :model="act_form" :rules="rules" ref="act_form">
        <!--  :toEnable="!showDetails" -->
        <el-form-item label="分享图片" prop="img">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'500*400'"
            :imgsPreview="mainImgStr"
            @getImgData="getImgData"

          />
        </el-form-item>
        <el-form-item label="分享标题" prop="shareTitle">
          <el-input
            class="form-input"
            placeholder="请输入分享标题"
            v-model="act_form.shareTitle"

          ></el-input>
          <!-- <span v-else>{{act_form.shareTitle}}</span>   v-if="!showDetails" -->
        </el-form-item>
        <el-form-item label="活动名称" prop="groupName">
          <el-input
            class="form-input"
            maxlength="15"
            show-word-limit
            placeholder="请输入活动名称"
            v-model="act_form.groupName"

          ></el-input>
          <!-- <span v-else>{{act_form.groupName}}</span> v-if="!showDetails" -->
        </el-form-item>
        <template v-if="!showDetails">
          <el-form-item label="活动时间" prop="act_date">
            <el-date-picker
              v-model="act_form.act_date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              end-placeholder="结束日期"
              :default-time="['00:00:00','23:59:59']"
              @change="getDateTime"
              :picker-options="datePickerOptions"
            ></el-date-picker>
          </el-form-item>
        </template>
        <el-form-item label="活动时间" prop="act_date" v-else>
          <span>
            {{this.act_form.act_date[0] | dataFormat('yyyy-MM-dd hh:mm:ss')}} 至
            {{this.act_form.act_date[1] | dataFormat('yyyy-MM-dd hh:mm:ss')}}
          </span>
        </el-form-item>

        <el-form-item label="开团限制" prop="startLimit">
          <el-input-number
            v-if="!showDetails"
            class="form-input-200"
            v-model="act_form.startLimit"
            controls-position="right"
            :min="0"
          ></el-input-number>
          <span v-else>{{act_form.startLimit}}次</span>
          <span class="c-9 ml-15" v-if="!showDetails">次</span>
          <span class="c-9 ml-30" v-if="!showDetails">注：输入0代表不限制次数</span>
        </el-form-item>
        <el-form-item label="参团限制" prop="joinLimit">
          <el-input-number
            v-if="!showDetails"
            class="form-input-200"
            v-model="act_form.joinLimit"
            controls-position="right"
            :min="0"
          ></el-input-number>
          <span v-else>{{act_form.joinLimit}}次</span>
          <span class="c-9 ml-15" v-if="!showDetails">次</span>
          <span class="c-9 ml-30" v-if="!showDetails">注：输入0代表不限制次数</span>
        </el-form-item>
        <el-form-item label="成团人数" prop="groupLimit">
          <el-input
            v-model.number="act_form.groupLimit"
            :min="0"
            class="form-input-200"
            v-if="!showDetails"
          />
          <span v-else>{{act_form.groupLimit}}人</span>
          <span class="c-9 ml-15" v-if="!showDetails">注：必须为整数</span>
        </el-form-item>
        <el-form-item label="是否内购" prop="isInternalPurchase">
          <el-radio-group v-model="act_form.isInternalPurchase">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拼团商品" prop="list">
          <div>
            <el-button
              v-if="!showDetails"
              type="primary"
              plain
              @click="selectGoods(act_form.list)"
            >{{act_form.list&&act_form.list.length>=1?'重新选择':'添加'}}</el-button>
          </div>
          <!-- 拼团商品列表 -->
          <el-table
            class="mt-24 member-table"
            :data="act_form.list"
            width="70%"
            v-if="act_form.list.length>0"
          >
            <template v-for="(item) in tableHeader">
              <el-table-column :label="item.value"  :key="item.key" v-if='item.key !== "operations"'>
                <template slot-scope="scope" >
                  <el-avatar
                    v-if='item.key == "imgUrl"'
                    :src="picsPath(scope.row.imgUrl)"
                    shape="square"
                    alt="加载失败"
                    :key="scope.row.imgUrl"
                  ></el-avatar>
                  <template v-else>{{scope.row[item.key]}}</template>

                </template>
              </el-table-column>
            </template>
            <el-table-column label="抢购价">
              <template slot-scope="scope">
                <div v-if="!showDetails">
                  <el-form-item
                    :prop="'list.' + scope.$index +'.activityPrice'"
                    :rules="[{validator:(x,y,z)=>validateActivityPrice(x,y,z,scope.row.price),required: true,trigger: 'blur'}]"
                  >
                    <el-input
                      v-model="scope.row.activityPrice"
                      placeholder="请输入抢购价"
                      @keyup.native="scope.row.activityPrice = oninput(scope.row.activityPrice)"
                    ></el-input>
                  </el-form-item>
                </div>
                <span v-else>{{scope.row.activityPrice}}</span>
              </template>
            </el-table-column>
            <el-table-column label="活动库存" prop="activityStock">
              <template slot-scope="scope">
                <el-form-item
                  v-if="!showDetails"
                  :prop="'list.' + scope.$index +'.activityStock'"
                  :rules="[{validator:(x,y,z)=>validateActivityStock(x,y,z,scope.row.stock),required: true,trigger: 'blur'}]"
                >
                  <el-input
                    v-model="scope.row.activityStock"
                    placeholder="请输入活动库存"
                    @keyup.native="scope.row.activityStock = oninput(scope.row.activityStock,0)"
                  ></el-input>
                </el-form-item>
                <span v-else>{{scope.row.activityStock}}</span>
              </template>
            </el-table-column>
            <el-table-column label="打印配置" prop="printer" width="150px">
              <template slot-scope="scope">
                <el-form-item
                        v-if="!showDetails"
                        :prop="'list.' + scope.$index +'.printer'"
                >
                  <el-select v-model="scope.row.printer" placeholder="不打印">
                    <el-option
                            v-for="item in printerOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <span v-else>{{getPrinter(scope.row.printer)}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="成团有效期" prop="invalidTime">
          <!-- v-if="!showDetails" -->
          <el-input
            
            class="form-input-200"
            v-model.number="act_form.invalidTime"
            controls-position="right"
            :min="1"
          >
            <template slot="append">分钟</template>
          </el-input>
          <!-- v-else -->
          <span >{{act_form.invalidTime}}分钟</span>
          <p class="c-9" v-if="!showDetails">注：请输入大于1的整数</p>
        </el-form-item>
        <el-form-item label=" 默认开启虚拟拼团" prop='virtual' label-width='160px'>
          <!-- <template v-if="!showDetails">
            <el-radio :label="1" v-model="act_form.virtual">是</el-radio>
            <el-radio :label="0" v-model="act_form.virtual">否</el-radio>
          </template>
          <template v-else>{{act_form.virtual?'是':'否'}}</template> -->
        </el-form-item>
        <el-form-item label="活动规则" prop="groupRules">
          <el-input
            v-if="!showDetails"
            type="textarea"
            v-model="act_form.groupRules"
            class="form-input"
            maxlength="500"
            :autosize="{ minRows: 5}"
            show-word-limit
          ></el-input>
          <div v-else>{{act_form.groupRules}}</div>
        </el-form-item>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button plain class="form-button" @click="cancel">取消</el-button>
      <el-button class="form-button" type="primary" @click="submit">确定</el-button>
    </div>

    <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      :selectArr ="afferentArr"
      @getSelectedList="getSelectedList"
      :selected='true'
    ></DepartmentGoodsDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import UploadPics from "@/components/UploadPics";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import { getProcudtList} from "@/api/online_department_store/commodity_manage"
import { subGroupForm, getGroupDetail} from "@/api/maketing/groupbuy"
export default {
  components: {
    Pagination,
    UploadPics,
    DepartmentGoodsDialog
  },
  data() {
    var validateGroupLimit = (rule, value, callback) => {
      if (!String(value)) {
        callback(new Error("成团人数不能为空"));
      } else if (!/(^[1-9]\d*$)/.test(value) || value == 0) {
        callback(new Error("请输入大于0的整数"));
      } else {
        callback();
      }
    };
    var validateInvalidTime = (rule, value, callback) => {
      if (!String(value)) {
        callback(new Error("成团有效期不能为空"));
      } else if (!/(^[1-9]\d*$)/.test(value) || value < 1) {
        callback(new Error("请输入大于1的整数"));
      } else {
        callback();
      }
    };
    var validateActivityPrice = (rule, value, callback, price) => {
      if (!String(value)) {
        callback(new Error("抢购价不能为空"));
      } else if (isNaN(value) || value < 0) {
        value = "";
        callback(new Error("请输入不小于0的数字"));
      } else if (value > price) {
        callback(new Error("抢购价不能超过实价"));
      } else {
        callback();
      }
    };
    var validateActivityStock = (rule, value, callback, stock) => {
      if (!String(value)) {
        callback(new Error("活动库存不能为空"));
      } else if (isNaN(value) || value < 0) {
        callback(new Error("请输入不小于0的整数"));
      } else if (value > stock) {
        callback(new Error("活动库存不能大于库存"));
      } else {
        callback();
      }
    };
    return {
      validateActivityPrice,
      validateActivityStock,
      act_form: {
        virtual: 1,
        shareTitle: '',
        groupName: "",
        goodsId: "",
        act_date: [],
        endTime: "",
        invalidTime: "",
        startLimit: "",
        joinLimit: "",
        isInternalPurchase: 0,
        status: 1,
        img: "",
        goodsType: "",
        list: [],
        groupLimit: "",
        groupRules: ""
      },
      rules: {
        img: [{ required: true, message: "分享图片不能为空", trigger: "blur" }],
        shareTitle: [{ required: true, message: "分享标题不能为空", trigger: "blur" }],
        groupLimit: [
          { validator: validateGroupLimit, required: true, trigger: "blur" }
        ],
        virtual:[{ required: true, trigger: "blur" }],
        groupName: [
          { required: true, message: "活动名称不能为空", trigger: "blur" }
        ],
        act_date: [
          { required: true, message: "活动时间不能为空", trigger: "blur" }
        ],
        startLimit: [
          { required: true, message: "开团限制不能为空", trigger: "blur" }
        ],
        joinLimit: [
          { required: true, message: "参团限制不能为空", trigger: "blur" }
        ],
        invalidTime: [
          {
            validator: validateInvalidTime,
            required: true,
            trigger: "blur"
          }
        ],
        list: [
          { required: true, message: "拼团商品不能为空", trigger: "blur" }
        ],
        groupRules: [
          { required: true, message: "活动规则不能为空", trigger: "blur" }
        ]
      },
      addFlag: true,
      actId: "",
      mainImgStr: "",
      showDetails: false,
      datePickerOptions: this.limitDate(), // 日期范围限制
      tableHeader: [  // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode"
        },
        {
          value: "商品图片",
          key: "imgUrl"
        },
        {
          value: "商品名称",
          key: "goodsName"
        },
        {
          value: "所属门店",
          key: "storeName",
        },
        {
          value: "一级分类",
          key: "firstClassifyName"
        },
        {
          value: "二级分类",
          key: "secondClassifyName"
        },
        {
          value: "实价",
          key: "price"
        },
        {
          value: "库存",
          key: "stock"
        },
        // {
        //   value: "操作",
        //   key: "operations"
        // }
      ],
      printerOptions: [{
        value: 0,
        label: '不打印'
      }, {
        value: 1,
        label: '超市打印机'
      }, {
        value: 2,
        label: '百货打印机'
      }],
      defaultPrinter: 0,
      afferentArr:[], //传到子组件  商品列表
    };
  },
  mounted() {
    this.handleOpeType();
  },
  filters: {
    goodsTypeFilter(val) {
      switch (val) {
        case 1:
          return "商品";
        case 2:
          return "优惠券";
        case 3:
          return "商兑换券";
        default:
          return "--";
      }
    }
  },
  methods: {
    //   获取已勾选商品   子组件传值
    getSelectedList(data, index) {
       console.log(data)
      // console.log(index)
      let info = JSON.parse(JSON.stringify(data))
      // delete info.createTime
      // 统一数据结构
      // let goodsItem = {
      //   goodsId: data.goodsId,
      //   activityStock: '',
      //   activityPrice: '',
      //   goodsInfo: [...info]
      // }
      console.log(info)
      //this.act_form.list = [goodsItem];
      this.act_form.list = info;
    },
    // getTableList(data){ //根据搜索条件改变列表
    //   getProcudtList({goodsStatus: 1, ...data}).then(res => {
    //      let data = this.$refs.GoodsDialog;
    //     data.total = res.data.body.total;
    //     data.tableData = res.data.body.list;
    //   });
    // },
     // 打开商品列表弹窗
    selectGoods(products) {
      // console.log(products, "list");
      let data = this.$refs.GoodsDialog;
      data.act_form.list = products;
        this.afferentArr = products;
      data.selected_temp = JSON.parse(JSON.stringify(products));
      data.showGoods = true;
      data.getGoods(false);
    },
    // 删除已选商品
    deleteGoods(idx) {
      this.act_form.list.splice(idx, 1);
      this.$refs.GoodsDialog.selected_temp = JSON.parse(
        JSON.stringify(
          this.act_form.list
        )
      );
        this.afferentArr = JSON.parse(
        JSON.stringify(
          this.act_form.activityGoodsVO
        )
      );
    },
    // 限制输入，flag:1 -- 输入数字保留小数点，flag:0 -- 输入整数
    oninput(num, flag = 1) {
      var str = num;
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      if (flag) {
        //限制只能输入一个小数点
        if (str.indexOf(".") != -1) {
          var str_ = str.substr(str.indexOf(".") + 1);
          if (str_.indexOf(".") != -1) {
            str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
          }
        }
        //正则替换
        str = str.replace(/[^\d^\.]+/g, ""); // 保留数字和小数点
        str = str.replace(/\.\d{3,}$/, ""); // 小数点后只能输两位
      } else {
        str = str.replace(/[^\d^]+/g, ""); // 保留数字
      }
      return str;
    },
    // 日期范围限制今天之后（包含今天）
    limitDate() {
      return {
        disabledDate: time => {
          const oneDay = 24 * 3600 * 1000;
          return time.getTime() < new Date().getTime() - oneDay;
        }
      };
    },
    //活动编辑态处理
    handleOpeType() {
      console.log(sessionStorage.getItem("ADD_FLAG"))
      console.log(sessionStorage.getItem("CHECK_GROUP_DETAILS"))
      console.log(this.$route.params)
      let params = this.$route.params;
      this.addFlag =
        params.addFlag != undefined
          ? params.addFlag
          : sessionStorage.getItem("ADD_FLAG");
      this.addFlag === "false" ? (this.addFlag = false) : "";
      this.showDetails =
        params.checkDetails != undefined
          ? params.checkDetails
          : sessionStorage.getItem("CHECK_GROUP_DETAILS");
      this.showDetails === "false" ? (this.showDetails = false) : "";
      if (params.addFlag != undefined)
        sessionStorage.setItem("ADD_FLAG", params.addFlag);
      if (params.checkDetails != undefined)
        sessionStorage.setItem("CHECK_GROUP_DETAILS", params.checkDetails);
      if (params.id) sessionStorage.setItem("ACT_ID", params.id);
      if (!this.addFlag) {
        // 编辑
        this.actId = params.id || sessionStorage.getItem("ACT_ID");
        this.getActData(this.actId);
      }
    },
    //获取活动详情
    getActData(id) {
      getGroupDetail(id).then(res => {
        let data = res.data.body;
        data.virtual = 1
        data.act_date = [
          this.$options.filters.dataFormat(
            data.beginTime,
            "yyyy-MM-dd hh:mm:ss"
          ),
          this.$options.filters.dataFormat(
            data.endTime,
            "yyyy-MM-dd hh:mm:ss"
          )
        ];
        data.list.forEach(ele=>{
          ele = Object.assign(ele,ele.goodsInfo)
        })
         console.log(data)
        this.act_form = data;
        this.mainImgStr = data.img;
      })
    },
    // 判断为空值,t=1则空值类型包含0与'0'
    isEmpty(v, t = 0) {
      switch (typeof v) {
        case "undefined":
          return true;
        case "string":
          if (v.length == 0 || t ? v === "0" : false) return true;
          break;
        case "boolean":
          if (!v) return true;
          break;
        case "number":
          if (isNaN(v) || t ? v === 0 : false) return true;
          break;
        case "object":
          if (null === v) {
            return true;
          } else if (!Object.keys(v).length) {
            return true;
          }
          break;
        default:
          return false;
      }
    },
    //获取日期时间
    getDateTime() {
      this.act_form.beginTime = this.act_form.act_date[0];
      this.act_form.endTime = this.act_form.act_date[1];
    },
    // 获取图片数据
    getImgData(imgData, type) {
      type == "main" ? (this.act_form.img = imgData.mainImg) : "";
    },
    // 返回
    cancel() {
      this.$router.go(-1);
    },
    // 提交表单-验证
    submit() {
      this.$refs["act_form"].validate(valid => {
        if (!valid) {
          this.$message.warning("表单信息不完整（有误）");
          return;
        }
        this.submitForm();
      });
    },
    // 提交表单-提交
    submitForm() {
      let time_data = {
        beginTime: this.act_form.act_date[0],
        endTime: this.act_form.act_date[1]
      };
      let method = "post";
      let data = JSON.parse(
        JSON.stringify(Object.assign(this.act_form, time_data))
      );
      delete data.act_date;
      if (data.list && data.list.length > 0) {
        data.list.forEach(item => {
          delete item.goodsInfo;
          delete item.groupId;
          delete item.id;
        });
      }
      if (!this.addFlag) {
        method = "put";
        data.id = this.actId;
      }
      data.list.map(item => {
        item.createTime = ''
        item.activityPrice =
          item.activityPrice[item.activityPrice.length - 1] === "."
            ? item.activityPrice.substr(0, item.activityPrice.length - 1)
            : item.activityPrice;
      });
      subGroupForm(data, this.addFlag ? 0 : 1).then(res => {
        this.$message.success("操作成功");
        this.$router.go(-1);
      })
    },
    getPrinter (printer) {
      let options = this.printerOptions
      for(let i = 0; i < options.length; i++) {
        if(printer == options[i].value) {
          return options[i].label
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>
