import request from '@/utils/request'

// 多人拼团新建、编辑提交
export function subGroupForm (data, type) {//type 0 新增 1编辑
    return request({
        url: '/small-inteface/group/',
        method: type ==1 ? 'put' : 'post',
        data
    })
}

//获取多人拼团活动详情 跟下面第三个是用一个接口  由于不用传参 分两个写
export function getGroupDetail (id) {
    return request({
        url: `/small-inteface/group/group/${id}`,
        method: 'get'
    })
}

//获取多人拼团活动列表
export function getGroupList (params ) {
    return request({
        url: '/small-inteface/group/',
        method: 'get',
        params
    })
}

//删除多人拼团活动
export function deleteGroup (id ) {
    return request({
        url: "/small-inteface/group/?id=" + id,
        method: 'delete'
    })
}

//接口：根据活动id获取活动详情
//地址：http://${BASE_URL}/small-inteface/group/group/{id}?page=${page}&size=${size}&status=${status}&orderNo=${orderNo}&goodsName=${goodsName}
//根据ID获取所有参加此多人拼团活动团长的列表
export function getGroupPersonList (data) {
    return request({
        url: `/small-inteface/group/group/${data.id}?page=${data.page}&size=${data.size}&status=${data.status}&orderNo=${data.orderNo}&goodsName=${data.goodsName}`,
        method: 'get'
    })
}

//根据团长ID获取多人拼团详情
export function getGroupPersonDetail (id ) {
    return request({
        url: "/small-inteface/group/created/" + id,
        method: 'get'
    })
}